<template>
  <!-- begin::FaresStandard new -->
  <div>
    <div class="manager-title">
      <div>
        {{ $t("FARES.STANDARD.TITLE_ADD_2") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <FaresStandardEdit :fare-standard-id="-1" />
  </div>
  <!-- end::FaresStandard new -->
</template>

<script>
import FaresStandardEdit from "@/view/pages/manager/fares/standard/Edit.vue";

export default {
  name: "FaresStandardNew",
  components: {
    FaresStandardEdit
  }
};
</script>
